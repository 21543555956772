<template>
  <div class="wh-space-bottom">
    <b-card>
      <edit-field-header
        :title="title"
        @routerBack="$router.back()"
      />

      <edit-field
        :is-edit="true"
        :fields="fields"
        :row-data="row_data"
        post-api="/kioskmanage/EditDeviceModel"
        finish-router="kioskPeripheralModel"
        permission="Kiosk_Peripheral_Model_Edit"
        @beforeSubmit="beforeSubmit"
      >
      </edit-field>
    </b-card>
  </div>
</template>

<script>
import fields, { reloadFieldData } from './fields'
import EditField from '@/components/EditField2'
import EditFieldHeader from '@/components/EditFieldHeader'
import common from '@/common'
import { flattenObject, expandObject } from '@/libs/ez-utils'
import kioskUtils from "@/libs/kiosk-utils";

export default {
  name: 'Edit',
  components: {
    EditFieldHeader,
    EditField,
    fields,
  },

  data() {
    return {
      title: common.getMenuName('kioskPeripheralModel') + ' - ' + common.getI18n('common.edit'),
      id: this.$router.currentRoute.params.id,
      fields,
      row_data: {
        purposes: [],
      },
    }
  },

  created() {
    reloadFieldData()
  },

  mounted() {
    this.id = common.decrypt(this.id)
    if (this.id === undefined) {
      this.$router.replace({ path: '/' + this.$route.path.split('/')[1] })
      return false
    }
    common.apiGetData('/kioskmanage/GetDeviceModel?modelID=' + this.id)
      .then(res => {
        const model = res.data.model
        this.row_data = model

        // 如果 purposes 爲空或不在預設範圍
        if(model.purposes?.length === 0 || !model.purposes?.some(p => kioskUtils.purposes.includes(p))) {
          this.row_data.purposes = []
          this.fields.find(f => f.name === 'purposes').edit_disable = false
        }
      })
  },

  methods: {
    beforeSubmit(callback) {
      const row_data = this.row_data
      callback(true, {
        "ModelID": row_data.deviceModelID,
        "Name": row_data.name,
        "Brand": row_data.brand,
        "Model": row_data.model,
        "BleName": row_data.bleName,
        "ModelRemark": row_data.modelRemark,
        "Purposes": row_data.purposes,
      })
    },
  },
}
</script>

<style scoped>

</style>
